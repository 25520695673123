export default class {
  constructor(url, title) {
    this.meta_tags = [url, title]
  }

  facebookShare(element) {
    element.setAttribute('target', '_blank')
    element.setAttribute('href', `https://www.facebook.com/sharer/sharer.php?u=${this.meta_tags[0]}&amp;src=sdkpreparse`)
  }

  twitterShare(element) {
    let title = this.meta_tags[1].replace(/\s/g, '%20')
    let tweet = `https://twitter.com/intent/tweet?url=${this.meta_tags[0]}&text=${title}&via=KeObra`
    element.setAttribute('target', '_blank')
    element.setAttribute('href', tweet)
  }
}
