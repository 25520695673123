import { Controller } from 'stimulus'
import Choices from 'choices.js'
import Api from '../../api/base'

export default class extends Controller {

  static targets = ['selectize_theme']

 connect() {

    const selectizeTheme = new Choices(
      this.selectize_themeTarget, {itemSelectText: ''}
    );

    let questions;
    let api = new Api('v1/frequent_questions')

    api.all(function(results) {
      questions = [
        ...results.generals, ...results.cimentation, ...results.brickwork,
        ...results.tool_usage, ...results.finishes, ...results.instalations,
        ...results.structures
      ]
      renderQuestions(questions)
    })

    this.selectize_themeTarget.addEventListener(
      'change',
      function(event) {
        api.all(function(results) {
          switch(event.detail.value) {
            case 'generals':
              questions = results.generals;
              break;
            case 'cimentation':
              questions = results.cimentation;
              break;
            case 'brickwork':
              questions = results.brickwork;
              break;
            case 'tool_usage':
              questions = results.tool_usage;
              break;
            case 'finishes':
              questions = results.finishes;
              break;
            case 'instalations':
              questions = results.instalations;
              break;
            case 'structures':
              questions = results.structures;
              break;
            default:
              questions = [
                ...results.generals, ...results.cimentation, ...results.brickwork,
                ...results.tool_usage, ...results.finishes, ...results.instalations,
                ...results.structures
              ]
          }
          renderQuestions(questions);
        }.bind(this),
          function(result, error) { console.log(error) },
          { state_id: event.detail.value }
        )
      },
      false,
    );

    function renderQuestions(questions) {
      let clear = document.querySelector('.questions-list').innerHTML = ''
      for (var key in questions) {
        let list = document.querySelector('.questions-list').innerHTML +=
        `<div class='accordion mb4'>
          <input id='toggle-${key}' type='checkbox' class='accordion-toggle' name='toggle'/>
          <label for='toggle-${key}' class='accordion-toggle'>
            <div class='b lh-copy'>${questions[key].question}</div>
          </label>
          <section class='lh-copy gray trix-content'>${questions[key].answer_text.body}</section>
        </div>`
      }
    }
  }
}
