import { Controller } from "stimulus"
import Share from '../../extensions/share'

export default class extends Controller {
  static targets = ['shareFB', 'shareTW', 'altShareFB', 'altShareTW']

  connect() {
    let share_fb = this.shareFBTarget
    let share_tw = this.shareTWTarget
    let alt_share_fb = this.altShareFBTarget
    let alt_share_tw = this.altShareTWTarget

    let meta = document.getElementsByClassName('meta-share')[0].dataset

    let share = new Share(
      meta.url,
      meta.title
    )

    share.facebookShare(share_fb)
    share.twitterShare(share_tw)
    share.facebookShare(alt_share_fb)
    share.twitterShare(alt_share_tw)
  }

  sharePinterest() {
    PinUtils.pinAny() 
  }
}
