import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['material_list_modal', 'submit_button', 'input', 'message']

  disableBtnInputsEmpty(){
    let disableBtn = false;
    this.inputTargets.forEach((i) => {
      if(i.value == null || i.value == ''){
        disableBtn = true;
      }
    })
    return disableBtn
  }

  enableSendButton(){
    let disableBtn = this.disableBtnInputsEmpty();
    this.submit_buttonTarget.disabled = disableBtn;
  }

  showMessage(){
    let disableBtn = this.disableBtnInputsEmpty();
    if(disableBtn) {
      this.messageTarget.classList.remove('dn');
    }else {
      this.messageTarget.classList.add('dn');
    }
  }

  open_material_list_modal() {
    this.material_list_modalTarget.classList.remove('dn');
  }

  close_material_list_modal() {
    this.material_list_modalTarget.classList.add('dn');
  }
}
