import { Controller } from "stimulus"
import Choices from 'choices.js'
import Api from '../../api/base'

export default class extends Controller {
  static targets = [
    'confirm_via',
    'rb_by_sms', 'rb_by_email',
    'selectize_profile', 'selectize_sex', 'selectize_city', 'selectize_state',
    'selectize_birthyear', 'construction_experience',
    'construction_experience_radio', 'worker_radio', 'profession', 'divElement'
  ]

  connect() {
    this.showProfession();
    if (this.hasSelectize_profileTarget) {
      const profileChoices = new Choices(this.selectize_profileTarget,
        {itemSelectText: '', position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados'}
      );
    }

    if (this.hasSelectize_sexTarget) {
      const sexChoices = new Choices(this.selectize_sexTarget,
        {itemSelectText: '',  position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados'}
      );
    }

    const selectizeState = new Choices(this.selectize_stateTarget,
      {itemSelectText: '',  position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados'}
    );

    const selectizeCity = new Choices(this.selectize_cityTarget,
      {itemSelectText: '',  position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados'}
    );

    if (this.hasSelectize_birthyearTarget) {
      const searchBirthyear = new Choices(this.selectize_birthyearTarget,
        {itemSelectText: '', shouldSortItems: false, shouldSort: false, position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados'}
      );
    }

    const _this = this;

    this.selectize_stateTarget.addEventListener(
      'change',
      function(event) {
        selectizeCity.clearChoices()
        var api = new Api('v1/city_catalogs/state_cities')
        api.all(function(results) {
          results = results.map(x => ({value: x.id, label: x.name}))
          results.unshift(({value: "", label: ""}))
          selectizeCity.setChoices(
            results,
            'value',
            'label',
            false,
          );
          selectizeCity.setChoiceByValue('');
          _this.typeLabelForCity(event.detail.value)
        }.bind(this),
          function(result, error) {console.log(error) },
          { state_id: event.detail.value }
        )
      },
      false,
    );

    this.scrollEvent();
    this.typeLabelForCity(this.selectize_stateTarget.value);
  }

  verifyEmail(e) {
    if(e.target.value == '') {
      this.confirm_viaTarget.classList.add('dn')
      this.rb_by_smsTarget.checked = true
      this.rb_by_emailTarget.checked = false
    } else {
      this.confirm_viaTarget.classList.remove('dn')
    }
  }

  scrollEvent() {
    setTimeout(() => {
      let error_valid = document.querySelectorAll('div.required div.error');
      if (error_valid.length != 0 ) {
        error_valid[0].scrollIntoView({ block: "center", behavior: "smooth" })
      }
    },400);
  }

  focusSelect(event){
    let inputCombo = event.currentTarget.querySelector('.choices__input.choices__input--cloned');
    let classActive = event.currentTarget.querySelector('.choices__list.choices__list--dropdown');
    event.stopPropagation();
    classActive.classList.add("is-active");
    inputCombo.blur();
    inputCombo.focus();
  }

  typeLabelForCity(state){
    if (state == 9) {
      document.querySelector('label[for="customer_city_catalog_id"]').innerHTML = "Alcaldía"
    }
    else {
      document.querySelector('label[for="customer_city_catalog_id"]').innerHTML = "Ciudad/Municipio"
    }
  }

  showConstruction(){
    this.removeHideClass()
    this.worker_radioTarget.firstElementChild.checked = true
    this.professionTargets
        .forEach(item => item.firstElementChild.checked = false)
    this.construction_experienceTarget
        .firstElementChild.checked = true
  }

  removeHideClass(){
    this.divElementTargets
        .forEach(item => item.classList.remove('hide'))
  }

  hideConstruction(){
    this.divElementTargets.forEach(item => item.classList.add('hide'))
    this.worker_radioTarget.checked = false
    this.construction_experienceTargets.
         forEach(item => item.firstElementChild.checked = false);
  }

  showProfession(){
    if (this.hasWorkerRadioTarget) {
      if(this.worker_radioTarget.firstElementChild.checked == true){
        this.removeHideClass()
      }
    }
  }
}
