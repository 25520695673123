// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("public_controllers")
require("core_mathison_controllers")

import AhoyTracker from '../ahoy/tracker'
let Ahoy = new AhoyTracker(null, true)
Ahoy.trackCustom()

document.addEventListener("turbolinks:load", function(){
  var toggle = document.getElementById('toggle');
  var overlay = document.getElementById('overlay');
  var menu = document.getElementById('menu');
  var close = document.getElementById('close');

  var openMenu = function(e){
    e.preventDefault();
    overlay.classList.remove('hide', 'fade-out');
    menu.classList.remove('hide', 'slide-out');
    overlay.classList.add('fade-in');
    menu.classList.add('slide-in');
  };
  var closeMenu = function(e){
    e.preventDefault();
    overlay.classList.remove('fade-in');
    overlay.classList.add('fade-out');
    menu.classList.remove('slide-in');
    menu.classList.add('slide-out');
    setTimeout(function() {
      overlay.classList.add('hide');
      menu.classList.add('hide');
    }, 800);
  };

  toggle.addEventListener('click', openMenu);
  overlay.addEventListener('click', closeMenu);
  close.addEventListener('click', closeMenu);
});
