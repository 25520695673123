import { Controller } from "stimulus"

export default class extends Controller {

  other_check() {
    if (document.getElementById('otherRadio').checked) {
      document.getElementById('otherDiv').classList.remove('dn');
      document.getElementById('otherField').focus();
    }
    else {
      document.getElementById('otherDiv').classList.add('dn');
      document.getElementById('otherField').value = "";
    }
  }
  
  connect() {
    window.addEventListener('beforeunload', function (e) {
      var date = new Date();
      date.setTime(date.getTime())
      document.cookie = "already_in=;expires="+date.toGMTString()+";path=/";
    });
  }
}
