import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['reducedForm', 'fullForm', 'newOnRender']

  connect() {
    if(this.newOnRenderTarget.dataset.render == 'true') {
      this.open_reduced_modal()
    }
  }

  open_reduced_modal() {
    this.reducedFormTarget.style.display = 'flex';
  }

  open_full_modal() {
    this.fullFormTarget.style.display = 'flex';
  }

  close_reduced_modal() {
    this.reducedFormTarget.style.display = 'none';
  }

  close_full_modal() {
    this.fullFormTarget.style.display = 'none';
  }
}