import { Controller } from  'stimulus'

export default class extends Controller {
  static targets = ['expiration', 'countdown_hours', 'countdown_minutes', 'countdown_seconds']

  connect() {
    this.setCountdown(this.expirationTarget.dataset.date)
  }

  setCountdown(date) {
    const _this = this
    var countDownDate = new Date(date).getTime()

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var hours = Math.floor(distance / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      _this.countdown_hoursTarget.innerHTML = _this.addZero(hours)
      _this.countdown_minutesTarget.innerHTML = _this.addZero(minutes)
      _this.countdown_secondsTarget.innerHTML = _this.addZero(seconds)
    }, 1000)
  }

  addZero(number){
    if(number > 9) return number
    return `0${number}`
  }
}
