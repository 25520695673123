import { Controller } from "stimulus"
import Choices from 'choices.js'
import Api from '../../api/base'

export default class extends Controller {
  static targets = ['selectize_profile', 'selectize_sex', 'selectize_birthyear',
                    'selectize_city', 'selectize_state']

  connect() {
    const selectizeProfile = new Choices(this.selectize_profileTarget,
      {itemSelectText: '',
      searchEnabled: false}
    );
    const selectizeBirthyear = new Choices(this.selectize_birthyearTarget,
      {itemSelectText: '', shouldSortItems: false, shouldSort: false}
    );
    const selectizeSex = new Choices(this.selectize_sexTarget,
      {itemSelectText: '',
      searchEnabled: false}
    );
    const selectizeState = new Choices(this.selectize_stateTarget,
      {itemSelectText: ''}
    );
    var selectizeCity = new Choices(this.selectize_cityTarget,
      {itemSelectText: ''}
    );
    this.selectize_stateTarget.addEventListener(
      'change',
      function(event) {
        selectizeCity.clearChoices()
        var api = new Api('v1/city_catalogs/state_cities')
        api.all(function(results) {
          results = results.map(x => ({value: x.id, label: x.name}))
          results.unshift(({value: "", label: ""}))
          selectizeCity.setChoices(
            results,
            'value',
            'label',
            false,
          );
          selectizeCity.setChoiceByValue('');
          if (event.detail.value == 9) {
            document.querySelector('label[id="city_tag_label"]').innerHTML = "Alcaldía"
          }
          else {
            document.querySelector('label[id="city_tag_label"]').innerHTML = "Ciudad"
          }
        }.bind(this),
          function(result, error) {console.log(error) },
          { state_id: event.detail.value }
        )
      },
      false,
    );
  }
}
