import ahoy from "ahoy.js";

export default class {

  constructor(conf = null, debug = false){
    this.config(conf)
    ahoy.debug(debug)
  }

  config(conf) {
    ahoy.configure(conf || {
      urlPrefix: "",
      visitsUrl: "/ahoy/visits",
      eventsUrl: "/ahoy/events",
      page: null,
      platform: "Web",
      useBeacon: true,
      startOnReady: true,
      trackVisits: true,
      cookies: true,
      cookieDomain: null,
      headers: {},
      visitParams: {},
      withCredentials: true,
      visitDuration: 0.5 * 60,
      visitorDuration: 2 * 365 * 24 * 60
    });
  }

  trackClick(properties) {
    ahoy.track('$click', properties)
  }

  trackCustom() {
    this.trackClicks()
    ahoy.trackView()
    // ahoy.trackSubmits()
    // ahoy.trackChanges()
  }

  trackClicks() {
    window.addEventListener('turbolinks:load', function() {
      let elemets = window.document.querySelectorAll('[data-track]')
      for (var i = 0; i < elemets.length; i++) {
        elemets[i].addEventListener('click', function(element) {
          let target = element.target.closest("[data-track]")
          let properties = {
            text:    target.innerText,
            url:     target.baseURI,
            href:    target.href,
            section: target.dataset.track,
            page:    window.location.pathname,
            tag:     target.tagName.toLowerCase()
          }
          ahoy.track('$click', properties)
        });
      }
    })
  }
}