import { Controller } from 'stimulus'
import Api from '../../api/base'

export default class extends Controller {
  static targets = ['concept_nav']

  connect() {

    const navConceptButton = this.concept_navTarget;

    let glossary;
    let current_id = window.location.pathname.slice(10)
    let char = window.location.search.slice(6)[0]
    let type = window.location.search.slice(13)
    let api = new Api('v1/concepts')

    api.all(function(results) {
      if (type == 'concepts') {
        glossary = glossarySort(results.concepts)
      } else if (type == 'materials') {
        glossary = glossarySort(results.materials)
      } else if (type == 'tools') {
        glossary = glossarySort(results.tools)
      } else if (type == 'all') {
        glossary = glossarySort([ ...results.concepts, ...results.materials, ...results.tools ])
      }
      setConceptNav(glossary[char])
    })

    function setConceptNav(concepts) {
      concepts.forEach(function(concept, index) {
        if (concept[1] == current_id) {
          return renderConceptNav(concepts, index)
        }
      });
    }

    function renderConceptNav(concepts, concept_index) {
      let first_id = concepts[0][1]
      let last_id = concepts.slice(-1)[0][1]
      let nav = { prev: null, next: null }
      if (first_id != current_id) {
        nav.prev = concepts[concept_index-1][1]
        navConceptButton.getElementsByClassName('prev')[0].href = `/concepts/${nav.prev}?char=${char}&type=${type}`
      } else {
        navConceptButton.getElementsByClassName('prev')[0].innerHTML = "<div class='flex items-center'></div>"
      }
      if (last_id != current_id) {
        nav.next = concepts[concept_index+1][1]
        navConceptButton.getElementsByClassName('next')[0].href = `/concepts/${nav.next}?char=${char}&type=${type}`
      } else {
        navConceptButton.getElementsByClassName('next')[0].innerHTML = "<div class='flex items-center'></div>"
      }
    }

    function glossarySort(concepts) {
      let glossary = {}
      concepts = concepts.sort(dynamicSort('name'))
      concepts.forEach(function(concept) {
        let index = concept['name'][0].toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        if (glossary[index] != null) {
          glossary[index].push([concept['name'], concept['id']])
        } else {
          glossary[index] = [[concept['name'], concept['id']]]
        }
      })
      return glossary;
    }

    function dynamicSort(property) {
      let sortOrder = 1;

      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      }
    }
  }
}
