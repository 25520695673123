import Choices from 'choices.js'
import Api from '../api/base'

export default class {

  constructor(element, controlOptions, options) {
    this.element = element;
    this.config = {
      placeholderValue: 'Busca...',
      loadingText: 'Cargando...',
      noResultsText: 'No hay resultados',
      noChoicesText: 'Escribe una palabra clave',
      itemSelectText: 'Presiona para seleccionar',
      shouldSort: false,
      searchFloor: 4,        // min query length
      searchChoices: true,
      searchEnabled: true,
      duplicateItemsAllowed: false,
      searchResultLimit: 5,
      editItems:false,
      maxItemCount: 1,
      ...controlOptions
    };

    this.lookupTimeout = null;
    this.lookupCache = {}
    this.choices = new Choices(this.element, this.config);
    this.choices.passedElement.element.addEventListener('search', this.onSearch.bind(this))

    this.options = {
      valueField: 'word',
      labelField: 'word',
      lookupDelay: 100,
      ...options
    }
    this.api = new Api(this.options.url)
  }

  onSearch(event) {
    clearTimeout(this.lookupTimeout);
    this.lookupTimeout = setTimeout(this.serverLookup.bind(this), this.options.lookupDelay);
  }

  destroy() {
    this.element.removeEventListener('search', this.onSearch)
    this.choices.destroy()
  }
  // Function to perform the API lookup.
  serverLookup() {
    // we can't use choices.currentValue because this is blank if we set searchChoices
    // to false. Instead we use the actual input field's value.
    var query = this.choices.input.value;
    if (query in this.lookupCache) {
      this.populateOptions(this.lookupCache[query]);
    } else {
      this.api.all(function(results) {
        results = this.parseResults(results)
        this.lookupCache[query] = results;
        this.populateOptions(results);
      }.bind(this),
        function(result, error) {console.log(error) },
        { query: query }
      )
    };
  }

  parseResults(data) {
    if(this.options.parseResultsCallback != null)
      return this.options.parseResultsCallback(data)
    return data
  }
  // Function to actually populate the results from the API lookup.
  populateOptions(options) {
    this.choices.clearChoices()
    // Now actually set the (de-duped) results.
    this.choices.setChoices(options, 'word', 'word', true);
  };
}
