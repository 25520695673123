import { Controller } from "stimulus"

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default class extends Controller {
  static targets = ["save_name_modal", "save_project_cookie", "share_modal",
                    "login_modal", "download_modal", "cancel_save_name_modal",
                    'storesGlide', 'carousel', 'download_confirmation_modal'
                   ]

  open_modal(event) {
    this.save_name_modalTarget.style.display = 'flex';
  }

  close_modal(event) {
    this.save_name_modalTarget.style.display = 'none';
  }

  open_share(event) {
    this.share_modalTarget.style.display = 'flex';
  }

  close_share(event) {
    this.share_modalTarget.style.display = 'none';
  }

  open_login(event) {
    let twenty_years = (60 * 60 * 24 * 365 * 20) + ";"
    let typeAction = event.currentTarget.dataset.actionButton

    if(typeAction){
      document.cookie = `return_to_rev=${typeAction}; path=/; max-age=${twenty_years}`
    }

    document.cookie = `project_id=${window.location.href.split('/')[5]}; path=/; max-age=${twenty_years}`
    this.login_modalTarget.style.display = 'flex';
  }

  open_cancel_save_name_modal(event) {
    this.cancel_save_name_modalTarget.style.display = 'flex';
  }

  close_cancel_save_name_modal(event) {
    this.cancel_save_name_modalTarget.style.display = 'none';
  }

  close_login(event) {
    this.login_modalTarget.style.display = 'none';
  }

  redirect_login(event) {
    var location = window.location.origin;
    window.location.href = location+"/auth/login";
  }

  open_download(event) {
    var stage = event.target.dataset.stage || event.target.parentElement.dataset.stage || event.target.parentElement.parentElement.dataset.stage
    const url = document.querySelector(".download_link").href.split('?')
    if (stage) {
      document.querySelector(".download_link").href = url[0] + "?stage=" + stage
    } else {
      document.querySelector(".download_link").href = url[0]
    }
    this.download_modalTarget.style.display = 'flex';

    // TODO:
    // This should not be solved this way, Glide instance should be generated
    // in the controller, this applies to all view-generated glide instances
    // DAGT December 1, 2020
    // Maybe will be useful later (the original way it was solved):
    // window.dispatchEvent(new Event('resize'));
    // window.dispatchEvent(new Event('resize'));

    this.glide_download = new Glide('.glide_download', {
      type: 'slider',
      bound: true,
      rewind: false,
      startAt: 0,
      perView: 2,
      gap: 12,
      breakpoints: {
        960: {
          perView: 1.2,
        },
        480: {
          perView: 1.2,
        }
      }
    }).mount()

    this.hidePrevButtonD()

    this.glide_download.on('run', function() {
      this.hidePrevButtonD()
      this.hideForwardButtonD()
    }.bind(this))
  }

  open_download_confirmation_modal() {
    this.download_confirmation_modalTarget.style.display = 'flex';
  }

  close_download_confirmation_modal() {
    this.download_confirmation_modalTarget.style.display = 'none';
  }

  hidePrevButtonD() {
    let prevButton = document.getElementsByClassName('glide__arrow__download--left')
    if (this.glide_download._c.Run.isStart() == true) {
      prevButton[0].style.visibility = 'hidden'
    } else {
      prevButton[0].style.visibility = ''
    }
  }

  hideForwardButtonD() {
    let forwardButton = document.getElementsByClassName('glide__arrow__download--right')
    if (this.glide_download._c.Run.isEnd() == true) {
      forwardButton[0].style.visibility = 'hidden'
    } else {
      forwardButton[0].style.visibility = ''
    }
  }

  close_download(event) {
    this.download_modalTarget.style.display = 'none';
  }

  copy_clipboard(event) {
    const URL = encodeURIComponent(window.location.href)
    const message = 'Consultalo en KeObra'
    let shareableLink = ''
    
    if(event.target.classList.contains('whatsapp')) {
      shareableLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}:%20${URL}`;
    } else if(event.target.classList.contains('twitter')) {
      shareableLink = `https://twitter.com/intent/tweet?url=${URL}&text=${message}`;
    } else if(event.target.classList.contains('facebook')) {
      shareableLink = `https://www.facebook.com/sharer/sharer.php?u=${URL}`
    } else if(event.target.classList.contains('messenger')) {
      shareableLink = `fb-messenger://share?link=${URL}`;
    }

    window.open(shareableLink, '_blank');
  }

  open_poll(event) {
    if (getCookie("already_in") != "yes" && getCookie("answered_poll") != "answered") {
      window.open('/poll');
    }

    if (getCookie("answered_poll") != "answered") {
      document.cookie = "already_in=yes;path=/";
    }
  }

  validate_name(event) {
    var input = document.querySelector("#project_name")
    var val = input.value;
    if (val == "") {
      const error_message =`
      <div class="error red" id="error-message">
      </div>
      `;
      event.preventDefault();
      input.insertAdjacentHTML('afterend', error_message);
      input.parentElement.querySelector('#error-message').innerText = 'Este campo es requerido'
    }
  }

  update_metadata_user_interactions(event) {
    if (event.target.classList.contains('download-btn')) {
      this.open_download_confirmation_modal();
    }
    let url = this.element.dataset.urlmetadatum
    const token = document.getElementsByName("csrf-token")[0].content
    let data = {}

    data['context_key'] = event.currentTarget.dataset.context_key === undefined ? event.currentTarget.dataset.contextKey : event.currentTarget.dataset.context_key
    data['context_target'] = event.currentTarget.dataset.context_target === undefined ? event.currentTarget.dataset.contextTarget : event.currentTarget.dataset.context_target
    data['full_name'] = event.currentTarget.dataset.full_name === undefined ? event.currentTarget.dataset.fullName : event.currentTarget.dataset.full_name
    data['visit_id'] = this.element.dataset.visitId

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json','X-CSRF-Token': token },
    })
    .then(response => response)
    .catch(error => console.log(error))
  }

  connect() {
    if (getCookie('return_to_rev') == 'download') {
      document.cookie = "return_to_rev=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      this.open_download();
    }

    if(this.hasCarouselTarget){
      this.reloadStoresGlide()
    }
  }

  reloadStoresGlide() {
    let maxCards = document.querySelectorAll('ul.glide-stores li').length
    let viewer = maxCards > 1 ? 1.2 : 1
    let peeker = maxCards > 1 ? 45 : 0
    let carousel = new Glide(this.carouselTarget, {
      type: 'slider',
      bound: true,
      startAt: 0,
      dragThreshold: false,
      perView: 3,
      rewind: false,
      gap: 12,
      breakpoints: {
        960: {
          dragThreshold: false,
          gap: 10,
          perView: 2,
        },
        480: {
          perView: 1,
          gap: 0
        }
      }
    }).mount()

    this.initControls(carousel)
    const _this = this
    carousel.on('run', function() {
      _this.hideLeftButton(carousel)
      _this.hideRightButton(carousel)
    })
  }

  hideLeftButton(carousel) {
    let prevButton = document.querySelector('.stores__arrows .glide__arrow--left')
    if (carousel._c.Run.isStart() == true) {
      prevButton.style.visibility = 'hidden'
    } else {
      prevButton.style.visibility = ''
    }
  }

  hideRightButton(carousel) {
    let forwardButton = document.querySelector('.stores__arrows .glide__arrow--right')
    if (carousel._c.Run.isEnd() == true) {
      forwardButton.style.visibility = 'hidden'
    } else {
      forwardButton.style.visibility = ''
    }
  }

  initControls(carousel) {
    let forwardButton = document.querySelector('.stores__arrows .glide__arrow--right')
    let prevButton = document.querySelector('.stores__arrows .glide__arrow--left')
    let totalCards = document.querySelectorAll('ul.glide-stores li').length

    if (totalCards > 1) {
      forwardButton.style.visibility = ''
      prevButton.style.visibility = 'hidden'
    } else{
      this.hideLeftButton(carousel)
      this.hideRightButton(carousel)
    }
  }
}
