import { Controller } from "stimulus"
import Choices from 'choices.js'
import Api from '../../api/base'
import SearchRemoteChoices from '../../utilities/search_choices_remote.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = ['selectize_search']

  connect() {
    const holderText = 'Buscar (ejemplo: "losa")'
    const searchChoices = new SearchRemoteChoices(this.selectize_searchTarget,
      {itemSelectText: '', placeholder: true, searchPlaceholderValue: holderText},
      {url: 'v1/search'}
    );

    this.selectize_searchTarget.addEventListener(
      'change',
      function(e) {
        var is_conf = document.querySelector('body').classList.value.includes('solutions configuration')
        if(is_conf) {
          var modal = document.querySelector('[id="unconcluded_modal"]');
          modal.style.display = 'flex';
          document.querySelector('.get_out').href = '/search?search%5Bsearch%5D=' + e.target.value
        } else {
          this.form.submit();
        }
      },
      false,
    );
    this.placeHolder(holderText)
  }

  focusSelect(event){
    let inputCombo = event.currentTarget.querySelector('.choices__input.choices__input--cloned');
    let classActive = event.currentTarget.querySelector('.choices__list.choices__list--dropdown');
    event.stopPropagation();
    classActive.classList.add("is-active");
    inputCombo.blur();
    inputCombo.focus();
  }

  placeHolder(holderText) {
    let element = this.element.querySelector('.choices__list.choices__list--single')
    if (element.querySelector('span.light-silver') != null){return false}
    element.insertAdjacentHTML('afterbegin',`<span class="light-silver">${holderText}</span>`)
  }
}
