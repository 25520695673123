import { Controller } from 'stimulus'
import Choices from 'choices.js'
import Api from '../../api/base'
import AhoyTracker from '../../ahoy/tracker'

export default class extends Controller {

  static targets = ['selectize_concept_type']

  connect() {

    const selectizeConceptType = new Choices(this.selectize_concept_typeTarget,
      {itemSelectText: ''}
    );

    let glossary, type;
    let Ahoy = new AhoyTracker(null, true)
    let api = new Api('v1/concepts')

    api.all(function(results) {
      type = 'all'
      glossary = glossarySort([])
      renderGlossary(glossary, type)
    })

    this.selectize_concept_typeTarget.addEventListener(
      'change',
      function(event) {
        api.all(function(results) {
          if (event.detail.value == 'concepts') {
            glossary = glossarySort(results.concepts)
            type = 'concepts'
            Ahoy.trackClick({ section: 'Concept#concepts' })
          } else if (event.detail.value == 'materials') {
            glossary = glossarySort(results.materials)
            type = 'materials'
            Ahoy.trackClick({ section: 'Concept#materials' })
          } else if (event.detail.value == 'tools') {
            glossary = glossarySort(results.tools)
            type = 'tools'
            Ahoy.trackClick({ section: 'Concept#tools' })
          } else {
            glossary = glossarySort([])
            type = 'all'
          }
          renderGlossary(glossary, type);
        }.bind(this),
          function(result, error) { console.log(error) },
          { state_id: event.detail.value }
        )
      },
      false,
    );

    function glossarySort(concepts) {
      let glossary = {}
      concepts = concepts.sort(dynamicSort('name'))
      concepts.forEach(function(concept) {
        let index = concept['name'][0].toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        if (glossary[index] != null) {
          glossary[index].push([concept['name'], concept['id']])
        } else {
          glossary[index] = [[concept['name'], concept['id']]]
        }
      })
      return glossary;
    }

    function renderGlossary(glossary, type) {
      let clear = document.querySelector('.glossary-list').innerHTML = ''
      for (var key in glossary) {
        let list = document.querySelector('.glossary-list').innerHTML +=
          `<div class="w-25-l w-third pa1 accordion">
              <input id="toggle-${key}" type="radio" class="accordion-toggle" name="toggle">
              <label class="db aspect-ratio aspect-ratio--1x1 bg-white" for="toggle-${key}">
                <div class="aspect-ratio--object z-4 flex items-center justify-center">
                  <span class="f-subheadline-ns f1 b">${key}</span>
                </div>
              </label>
              <section class="bg-white pa3">
                <ul class="list ma0 pa0 letter-list letter-${key}"></ul>
              </section>
           </div>`
        for (var concept in glossary[key]) {
          let letter_class = `.letter-${key}`
          let letter = document.querySelector(letter_class).innerHTML +=
            `<li>
              <a href="/concepts/${glossary[key][concept][1]}?char=${key}&type=${type}">
                ${glossary[key][concept][0]}
              </a>
            </li>`
        }
      }
    }

    function dynamicSort(property) {
      let sortOrder = 1;

      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      }
    }
  }
}
